<template>
  <div class="grid">
    <div class="col-12 md:col-12">
      <div class="card">
        <Panel header="Gerar Arquivo Movimento" class="mt-3">
          <form @submit.prevent="validate()">
            <div class="p-fluid formgrid grid">
              <div class="field col-3 md:col-3">
                <label for="entidade">Entidade</label>
                <Dropdown
                  id="entidade"
                  v-model="v$.entidade.$model"
                  :class="{
                    'p-invalid': submitted && v$.entidade.$invalid,
                  }"
                  optionLabel="nome"
                  :options="entidades"
                  :filter="true"
                  filterPlaceholder="Procure pelo nome da entidade"
                  :emptyFilterMessage="'Nenhuma entidade encontrada'"
                  placeholder="Selecione uma entidade">
                </Dropdown>
                <div
                  v-if="submitted && v$.entidade.required.$invalid"
                  class="p-error">
                  O campo entidade é obrigatório.
                </div>
              </div>

              <div class="field col-3 md:col-3">
                <label for="anoReferencia">Ano</label>
                <InputMask
                  id="anoReferencia"
                  v-model="v$.anoReferencia.$model"
                  mask="9999"
                  :class="{
                    'p-invalid': submitted && v$.anoReferencia.$invalid,
                  }" />
                <div
                  v-if="submitted && v$.anoReferencia.required.$invalid"
                  class="p-error">
                  O campo ano é obrigatório
                </div>
                <div
                  v-if="
                    submitted && v$.anoReferencia.anoMaioIgualAtual.$invalid
                  "
                  class="p-error">
                  O campo ano não pode ser menor do que o ano atual
                </div>
              </div>
              <div class="field col-3 md:col-3">
                <label for="mesReferencia">Mês (1 até 12)</label>
                <InputMask
                  id="mesReferencia"
                  v-model="v$.mesReferencia.$model"
                  mask="99"
                  :class="{
                    'p-invalid': submitted && v$.mesReferencia.$invalid,
                  }" />

                <div
                  v-if="submitted && v$.mesReferencia.required.$invalid"
                  class="p-error">
                  O campo mês é obrigatório
                </div>
                <div
                  v-if="submitted && v$.mesReferencia.numeroPermitido.$invalid"
                  class="p-error">
                  O campo mês deve ser entre 01 e 12
                </div>
              </div>
            </div>
            <Button type="submit" autofocus :disabled="saving || bloqueado">
              <span v-if="saving" class="pi pi-spin pi-spinner"></span>
              <span v-if="!saving" class="ml-2">Gerar Movimento</span>
              <span v-if="saving" class="ml-2">Aguarde</span>
            </Button>
          </form>
        </Panel>
        <Panel header="Arquivos" class="mt-3">
          <DataTable
            class="p-datatable-sm"
            :paginator="true"
            :rows="5"
            stripedRows
            :value="arquivos"
            dataKey="id"
            :filters.sync="filtros"
            :globalFilterFields="[
              'nomeArquivo',
              'mesReferencia',
              'anoReferencia',
              'statusProcessamento.mensagem',
              'entidade.nome',
            ]"
            filterDisplay="menu"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="rowsPerPageOptions"
            currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} arquivos"
            responsiveLayout="scroll">
            <template #empty> Nenhuma arquivo encontrado. </template>
            <template #loading> Carregando. Por favor aguarde. </template>
            <template #header>
              <div class="flex flex-column sm:flex-row">
                <span class="p-input-icon-left mb-2 mr-2">
                  <i class="pi pi-search" />
                  <InputText
                    v-model="filtros['global'].value"
                    placeholder="Pesquisar"
                    style="width: 100%" />
                </span>
                <Button
                  type="button"
                  icon="pi pi-filter-slash"
                  label="Limpar"
                  class="p-button-outlined mb-2"
                  @click="limparFiltro" />
              </div>
            </template>
            <Column :sortable="true" field="mesReferencia" header="Mês/Ano">
              <template #body="{ data }">
                {{
                  data.mesReferencia || data.anoReferencia
                    ? ('0' + data.mesReferencia).slice(-2) +
                      '/' +
                      data.anoReferencia
                    : '-'
                }}
              </template>
            </Column>
            <Column :sortable="true" field="nomeArquivo" header="Nome" />
            <Column :sortable="true" field="entidade.nome" header="Entidade" />
            <Column
              :sortable="true"
              field="statusProcessamento.mensagem"
              header="Status do Processamento" />
            <Column header="Ações">
              <template #body="{ data }">
                <Button
                  title="Arquivo"
                  label="Arquivo"
                  icon="pi pi-download"
                  class="mr-2 mb-2 p-button-sucess"
                  @click="baixarArquivo(data.nomeArquivo)" />
              </template>
            </Column>
          </DataTable>
        </Panel>
      </div>
    </div>
  </div>
</template>

<script>
import { saveAs } from 'file-saver'
import UseVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { FilterMatchMode, FilterOperator } from 'primevue/api/'
import FiltroGerarMovimento from '@/domain/FiltroGerarMovimento.js'
import EntidadeService from '@/service/EntidadeService.js'
import ApiCargaService from '@/service/ApiCargaService.js'
import AjusteParametrosService from '@/service/AjusteParametrosService.js'

//custom validators
const anoMaioIgualAtual = (value) => validarCampoAnoMaioIgualAnoAtual(value)

function validarCampoAnoMaioIgualAnoAtual(value) {
  let hoje = new Date()
  let ano = hoje.getFullYear()
  if (value) {
    return value >= ano ? true : false
  }
}

const numeroPermitido = (value) =>
  validarCampoMesReferenciaNumeroPermitido(value)

function validarCampoMesReferenciaNumeroPermitido(value) {
  return value && value > 0 && value <= 12 ? true : false
}

export default {
  setup() {
    return { v$: UseVuelidate() }
  },

  data() {
    return {
      filtroGerarMovimento: new FiltroGerarMovimento(),
      entidade: null,
      anoReferencia: null,
      mesReferencia: null,
      entidades: [],
      arquivos: [],
      submitted: false,
      totalRecords: 0,
      filtros: {},
      loading: false,
      saving: false,
      bloqueado: false,
    }
  },

  validations() {
    return {
      entidade: { required },
      anoReferencia: {
        required,
        anoMaioIgualAtual,
      },
      mesReferencia: {
        required,
        numeroPermitido,
      },
    }
  },

  computed: {
    rowsPerPageOptions() {
      if (this.totalRecords < 5) {
        return null
      }
      return [5, 10, 25]
    },
  },

  created() {
    this.entidadeService = new EntidadeService(this.$http)
    this.ApiCargaService = new ApiCargaService(this.$http)
    this.AjusteParametrosService = new AjusteParametrosService(this.$http)
    this.initFiltros()
    this.verificaBloqueio()
  },

  mounted() {
    this.carregarEntidade()
    this.carregarAnoMesAtual()
    this.carregarArquivos()
  },

  methods: {
    async verificaBloqueio() {
      const flagsBloqueadas =
        await this.AjusteParametrosService.getFlagsBloqueadas()
      if (flagsBloqueadas.includes('MOVIMENTO')) {
        this.bloqueado = true
        this.exibeToast(
          'info',
          `A(s) funcionalidade(s) ${flagsBloqueadas.join(
            ', ',
          )} estão temporariamente desabilitadas, tente novamente mais tarde!`,
        )
      }
    },
    initFiltros() {
      this.filtros = {
        global: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.CONTAINS },
            { value: null, matchMode: FilterMatchMode.EQUALS },
          ],
        },

        'statusProcessamento.mensagem': {
          operator: FilterOperator.OR,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },

        nomeArquivo: {
          operator: FilterOperator.OR,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
      }
    },

    limparFiltro() {
      this.initFiltros()
    },

    carregarEntidade() {
      this.entidadeService.getListaEntidades().then((res) => {
        this.entidades = res
      })
    },

    carregarArquivos() {
      this.ApiCargaService.getListaArquivos(['MOVIMENTO']).then((res) => {
        this.arquivos = res
      })
    },

    carregarAnoMesAtual() {
      const date = new Date()
      this.mesReferencia = date.getMonth() + 1
      this.anoReferencia = date.getFullYear()
    },

    validate() {
      this.submitted = true
      this.v$.entidade.$touch()
      this.v$.anoReferencia.$touch()
      this.v$.mesReferencia.$touch()

      if (
        this.v$.entidade.$invalid ||
        this.v$.anoReferencia.$invalid ||
        this.v$.mesReferencia.$invalid
      ) {
        return
      } else {
        this.gerar()
      }
    },

    gerar() {
      this.atriuirValores()
      this.saving = true
      this.ApiCargaService.gerarMovimento(this.filtroGerarMovimento)
        .then(() => {
          this.exibeToast('success')
          this.limparCampos()
        })
        .catch((err) => {
          this.saving = false
          this.exibeToast('error', err.response.data.message)
        })
    },

    atriuirValores() {
      this.filtroGerarMovimento.entidadeId = this.entidade.id
      this.filtroGerarMovimento.anoReferencia = this.anoReferencia
      this.filtroGerarMovimento.mesReferencia = this.mesReferencia
    },

    limparCampos() {
      this.submitted = false
      this.v$.$reset()
      this.filtroGerarMovimento = new FiltroGerarMovimento()
      this.entidade = null
      this.anoReferencia = null
      this.mesReferencia = null
      this.saving = false
      this.initFiltros()
      this.carregarArquivos()
    },

    baixarArquivo(nomeArquivo) {
      this.ApiCargaService.baixarArquivo(nomeArquivo).then(
        (res) => {
          this.downloadFile(res.data, nomeArquivo)
        },
        (err) => {
          this.exibeToast('error', err.response.data.message)
        },
      )
    },

    downloadFile(response, nomeArquivo) {
      const blob = new Blob([response], { type: 'text/plain' })
      saveAs(blob, nomeArquivo)
    },

    downloadCSV(response, nomeArquivo) {
      const blob = new Blob([response], { type: 'text/csv' })
      saveAs(blob, nomeArquivo)
    },

    exibeToast(tipo, msg) {
      if (tipo === 'success') {
        this.$toast.add({
          severity: 'success',
          summary: 'Processamento solicitado.',
          life: 10000,
        })
      } else if (tipo === 'error') {
        this.$toast.add({
          severity: 'error',
          summary: msg,
          life: 10000,
        })
      } else if (tipo === 'info') {
        this.$toast.add({
          severity: 'info',
          summary: msg,
          life: 10000,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
button {
  margin: 0 2px;
}
</style>
